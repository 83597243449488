import "./App.css";
import Resume from "./Components/Resume/Resume";

export default function App() {
	return (
		<section>
			<Resume />
		</section>
	);
}
